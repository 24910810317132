import { For, onMount } from "solid-js";
import { animate } from "motion";

export function TextRoller(props: {
  word: string;
  delay?: number;
  distance?: number;
  duration?: number;
  initialDelay?: number;
}) {
  const { delay, distance, duration, initialDelay } = props;
  const words = props.word
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const letters = words.split("");
  return (
    <For each={letters}>
      {(letter, index) => {
        const i = index();
        let ref = undefined;
        onMount(() => {
          setTimeout(() => {
            animate(
              ref as any,
              {
                y: [0, distance ?? 10, 0],
              },
              {
                duration: duration ?? 0.5,
                delay: delay ?? 0.01,
                easing: "ease-in-out",
                repeat: Infinity,
              }
            );
            // s.finished.then(() => anim(s));
          }, (initialDelay ?? 50) * (i + 1));
        });
        return (
          <span ref={ref} class={letter === " " ? "mx-1" : ""}>
            {letter}
          </span>
        );
      }}
    </For>
  );
}
